import _glslTokenizer from "glsl-tokenizer";
import _glslTokenString from "glsl-token-string";
import _glslTokenInjectBlock from "glsl-token-inject-block";
var exports = {};
var tokenize = _glslTokenizer;
var stringify = _glslTokenString;
var inject = _glslTokenInjectBlock;

exports = function glslInjectDefine(source, defines) {
  if (!defines) {
    return source;
  }

  var keys = Object.keys(defines);

  if (keys.length === 0) {
    return source;
  }

  var tokens = tokenize(source);

  for (var i = keys.length - 1; i >= 0; i--) {
    var key = keys[i];
    var val = String(defines[key]);

    if (val) {
      // allow empty value
      val = " " + val;
    }

    inject(tokens, {
      type: "preprocessor",
      data: "#define " + key + val
    });
  }

  return stringify(tokens);
};

export default exports;